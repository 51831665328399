<template>
  <div class="order-management">
    <pageBread
      :img="() => require('../../assets/image/zhuye.png')"
      text="入库记录"
    ></pageBread>
    <div class="nqFrame" v-for="item in shipList" :key="item.oddNumber" v-wait="loading">
      <div class="flex">
        <h3>物流单号：{{ item.oddNumber }}</h3>
        <span>入库时间：{{ item.createTime }}</span>
        <span>入库原始重量：{{ item.weight }}</span>
        <span>状态：{{ item.stateName }}</span>
        <span>备注：{{ item.note }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { warehousingTnfo } from '../../utils/coupon.js'
export default {
  name: 'login',
  data() {
    return {
      showDetail: false,
      shipList: [
        // {
        //   oddNumber: '',
        //   createTime: '',
        //   weight: '',
        //   stateName: '',
        //   note: ''
        // }
      ],
      total: 0,
      pageNum: 1,
      pageSize: 15,
      loading: false
    }
  },
  created() {
    this._getList()
  },
  mounted() {},
  methods: {
    _getList() {
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.loading = true
      this.$reqPost(warehousingTnfo.list, params).then((res) => {
        this.loading = false
        if (res.data.code === 200) {
          this.shipList = res.data.data.list
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import url("../order-management/order.less");
</style>
