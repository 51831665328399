// 查看会员关联的优惠券
// export function getMyCouponsById(params) {
//   return request({
//     url: '',
//     method: 'post',
//     data: params
//   })
// }

// 获取我的优惠券
export const couponInfo = {
  list: '/member-coupons/getMyCouponsById'
}
// 我的仓库
export const orderInfo = {
  MemberId: '/stock-manage/getStockManageByMemberId', // 根据id 获取仓库信息
  HouseList: '/stock-manage/getInWareHouseListByMemberId', // 根据id 查询入库记录
  addOrderInfo: '/order-manage/addOrderInfo', // 打包
  TypeList: '/base-dict/getDeclaretTypeList', // 申报方式
  AddOrder: '/order-manage/getAddOrderInfo', // 提交打包-查看信息
  addForecast: '/stock-manage/addForecastInfo', // 添加预报物流信息
  CompanyList: '/base-dict/getLogisticsCompanyList', // 查看物流公司
  toPackage: '/order-manage/getAddOrderInfo', // 提交打包
  getBaseDict: '/base-dict/getDeclaretTypeList',
  deleteItem: '/stock-manage/delete/'
}
// 待发运 已付款
export const shippedInfo = {
  list: '/order-manage/getOrderListBymemberManager', // 获取列表
  payment: '/order-manage/getPayMoneyInfo', // 去付款
  item: '/order-manage/getOrderInfoByOrderId', // 查看详情
  payDetailInfo: '/order-manage/getPayMoneyInfo',
  linesList: '/line-manage/getLineManagerByOrderNum',
  getAllLines: '/line-manage/getLineManagerAllList',
  youhuiquan: '/member-coupons/getMyUseCouponsById',
  yhPrice: '/coupons/getPriceByCouponDiscount',
  toPayMoney: '/order-manage/updatePayMoney',
  weixinPay: '/t-order-info/addTOrderInfo',
  getOrderInfoList: '/package-weight/getOrderInfoList'
}
// 交易记录
export const cheapIofo = {
  list: '/finance-manage/getFinanceManageListByWx' // 获取列表
}
// 入库记录
export const warehousingTnfo = {
  list: '/stock-manage/getInWareHouseListByMemberId' // 获取列表
}
// 资讯列表
export const courtyIofo = {
  list: '/information-manage/getInformationManageListByNation', // 根据国家查询资讯列表信息 post
  detailedList: '/information-manage/getByIdWx', // 查看资讯信息详细信息 get
  questionList: '/information-manage/getInformationManageListByCommonProblem', // 查看常见问题资讯列表 post
  cheapList: '/information-manage/getInfomationListByDiscount', // 优惠活动-查看列表 post
  topList: '/information-manage/getInfomationListByOther' // 查看资讯管理列表物流常识、常见国家、公司动态，推荐阅读-微信端 post
}
// 联系我们
export const callInfo = {
  list: '/system-manager/getSystemManagerById', // 联系我们-信息展示 get
  callUs: '/evaluate-record/addEvaluateRecord' // 联系我们-留言 POST
}
// 评价列表
export const evaluateInfo = {
  list: '/evaluate-record/getListReviewsByWx' // post
}
// 领取包裹
export const parcelInfo = {
  list: '/stock-manage/getPendingStockManagerList', // 列表 post
  claim: '/stock-manage/updateUserClaimsPackage', // 认领
  details: '/stock-manage/getStockManagerById', // 详情 GET
  select: '/warehouse-manage/getWarehouseAllList' // 下拉框 get
}
// 包裹验货
export const yanhuoInfo = {
  list: '/order-manage/getPendingInspectionList', // 待验货列表 post
  detailed: '/order-manage/getInspectionDetail', // 查看详细信息
  sure: '/order-manage/updateOrderInspection' // 确认验货  get
}
